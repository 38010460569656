import React, { useEffect, useState, useMemo, useReducer, createContext } from 'react';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress, Box, DialogContent, Dialog, Button } from '@mui/material';
import { color, fontSize, height, styled, width } from '@mui/system';
import CryptoJS from 'crypto-js';
import PlayerDetailCard from './PlayerDetailCard';
import './Profile.css';
import { getComparator, stableSort } from '../newcoach/components/utilities';
import GameService from '../../services/game.service';
import TagVideo from './TagVideo';
import FullVideoPlayer from './videoDialog';
import { PLAYER_ICON_DEFAULT } from '../../common/staticData';
import { APP_IMAGE_DEFAULT } from '../../common/staticData';
import Header from "./Header";
import PlayerSkills from "./PlayerSkillCard";
import PlayerAdvanceStatsCard from "./PlayerAdvanceStatsCard";
import PlayerGameStatsCard from "./PlayerGameStatsCard";

const styles = {
    loader: {
        position: 'fixed',
        left: '0px',
        top: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    play: {
        color: '#07863d'
    },
    paper: { minWidth: '98%', maxHeight: 'none', backgroundColor: 'transparent' },
    pageDescription: {
        height: "100vh",
        width: "100vw",
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        color: "#fff",

    },
};
const defaultPrimaryColor = '#058240';
const defaultSecondColor = '#e7f3e5';

const secretKey = 'scouting4u'; // Use a secure method to store and retrieve your secret key

const decryptId = (encryptedId) => {
    const bytes = CryptoJS.AES.decrypt(encryptedId, secretKey);
    return parseInt(bytes.toString(CryptoJS.enc.Utf8), 10);
};

export const PlayerContext = createContext({
    context: {
        player: null,
        game: null,
        update_cnt: 0
    },
    setContext: () => { }
});

const PaperStyledDialog = styled(Dialog)(({ theme }) => ({
    paper: { minWidth: '98%', backgroundColor: 'transparent' }
}));

export default function Player() {
    const { t } = useTranslation();
    const { data } = useParams();
    // const navigate = useNavigate();
    const playerId = decryptId(data); // Decrypt the player ID here
    const [loading, setLoading] = useState(true);
    const [games, setGames] = useState([]);
    const [open, setOpen] = useState(false);
    const [fullVideo, setFullVideo] = useState(false);
    const [playTags, setPlayTags] = useState([]);
    const [context, setContext] = useReducer((old, action) => ({ ...old, ...action }), {});

    const [game, setGame] = useState(context.game);
    const value = useMemo(() => ({ context, setContext }), [context]);

    const [primaryColor, setPrimaryColor] = useState(defaultPrimaryColor);
    const [secondColor, setSecondColor] = useState(defaultSecondColor);
    const [language, setLanguage] = useState('en');

    const [seasonList, setSeasonList] = useState([]);
    const [seasonFilter, setSeasonFilter] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [curPlayGame, setCurPlayGame] = useState(null);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstalled, setIsInstalled] = useState(false);
    const [skillsPage, setSkillsPage] = useState(false);
    const [detailsPage, setDetailsPage] = useState(true);
    const [advanceStatsPage, setAdvanceStats] = useState(false);
    const [gameStatsPage, setGameStats] = useState(false);
    const [gameStatsPageData, setGameStatsData] = useState({});
    const [disableSeason, setDisableSeason] = useState(false);
    const [isActivated, setIsActivated] = useState(false);
    const [checkingActive, setCheckingActive] = useState(true);
    const [stopUpdates, setStopUpdates] = useState(false);

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    primary: { main: primaryColor },
                    secondary: { main: secondColor }
                }
            }),
        [primaryColor, secondColor]
    );

    const manifestJSON =
    {
        "name": "Scouting4U",
        "short_name": "Scouting4U",
        "scope": "http://localhost:5000/player/",
        "start_url": "http://localhost:5000/player/",
        "display": "standalone",
        "icons": [
            {
                "src": "",
                "sizes": "64x64 32x32 24x24 16x16",
                "type": "image/x-icon"
            },
            {
                "src": "",
                "type": "image/png",
                "sizes": "512x512"
            }

        ],
        "display": "standalone",
        "theme_color": "#000000",
        "background_color": "#ffffff"
    };

    const { player: playerData, game: curGame } = context;
    useEffect(() => {
        // Initial fetch to check if the player is activated
        setCheckingActive(true);
        GameService.getPlayerById(playerId).then((res) => {
            setContext({ player: res });
            if (res.player_active === false) {
                setIsActivated(false); // Player is not active
            } else {
                setIsActivated(true);  // Player is active
                setPrimaryColor(res.team_color || defaultPrimaryColor);
                setSecondColor(res.second_color || defaultSecondColor);
                GameService.getTeamById(res.team_id).then((result) => {
                    setLanguage(result.team_language);
                });
            }
            setCheckingActive(false); // End of loading
        });
    }, [playerId]);
    // useEffect(() => {
    //     // Initial fetch to check if the player is activated
    //     setLoading(true);
    //     GameService.getPlayerById(playerId).then((res) => {
    //         setContext({ player: res });
    //         if (res.player_active === false) {
    //             setIsActivated(false); // Player is not active
    //         } else {
    //             setIsActivated(true);  // Player is active
    //             setPrimaryColor(res.team_color || defaultPrimaryColor);
    //             setSecondColor(res.second_color || defaultSecondColor);
    //             GameService.getTeamById(res.team_id).then((result) => {
    //                 setLanguage(result.team_language);
    //             });
    //         }
    //         setLoading(false); // End of loading
    //     });
    // }, [playerId]);



    useEffect(() => {
        if (isActivated === false) {
            return;
        }
        setLoading(true);

        GameService.getGameDetailssByPlayer(playerId).then((res) => {
            const doneArray = res.filter((item) => item.done_tagging === true);
            const ascArray = stableSort(doneArray, getComparator('desc', 'date'));
            const seasons = getSeasonList(doneArray);

            setGames(ascArray);
            setSeasonList(seasons);
            // setSelectedIndex(doneArray.length > 0 ? 1 : 0);
            setSeasonFilter(seasons[0]);
        });

        GameService.getPlayerById(playerId).then((res) => {
            setContext({ player: res });
            // Define the start URL
            //manifestJSON.name = res.player_full_name;
            //manifestJSON.short_name = res.player_first_name.substring(0,1) + ". " + res.player_last_name.substring(0,1) + ".";
            manifestJSON.icons[1].src = APP_IMAGE_DEFAULT;
            //manifestJSON.icons[1].src= res.player_image ? res.player_image.includes(process.env.REACT_APP_S3_URI) ? res.player_image : process.env.REACT_APP_S3_URI + res.player_image : PLAYER_ICON_DEFAULT;
            const startUrl = `${window.location.origin}${window.location.pathname + window.location.search}`;
            manifestJSON.start_url = startUrl;

            // Create the manifest link element if it doesn't exist
            let manifestLink = document.querySelector('#my-manifest-placeholder');
            if (!manifestLink) {
                manifestLink = document.createElement('link');
                manifestLink.setAttribute('rel', 'manifest');
                manifestLink.setAttribute('id', 'my-manifest-placeholder');
                document.head.appendChild(manifestLink);
            }

            // Create the dynamic manifest
            const stringManifest = JSON.stringify(manifestJSON);
            const blob = new Blob([stringManifest], { type: 'application/json' });
            const manifestURL = URL.createObjectURL(blob);
            manifestLink.setAttribute('href', manifestURL);

            setPrimaryColor(res.team_color || defaultPrimaryColor);
            setSecondColor(res.second_color || defaultSecondColor);
            GameService.getTeamById(res.team_id).then((result) => {
                setLanguage(result.team_language);
            });
        });


        setLoading(false);
    }, [playerId, checkingActive]);

    useEffect(() => {
        i18next.changeLanguage(language);
    }, [language]);



    // const handleListItemClick = (event, index) => {
    //     console.log("index",index)
    //     if (index === 0) setSeasonFilter(null);
    //     else setSeasonFilter(seasonList[index - 1]);

    //     setSelectedIndex(index);
    //     setFilterAnchorEl(null);
    // };

    const getGameList = () => {
        for (var i = 0; i < games.length; i++) {
            games[i].id = games[i].game_id
        }
        const newList = seasonFilter ? games.filter((game) => game.season_name === seasonFilter.name) : games;
        return stableSort(newList, getComparator('desc', 'game_date'));
    };

    const getSeasonList = (array) => {
        if (array.length > 0) {
            const desc = stableSort(array, getComparator('desc', 'season_id'));
            let result = [];

            desc.map((item) => {
                const filter = result.filter((season) => season.name === item.season_name);
                if (filter.length === 0) result = [...result, { name: item.season_name, id: item.season_id }];
                return result;
            });
            return result;
        }
        return [];
    };


    useEffect(() => {
        if (stopUpdates) {
            return;
        }
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        const checkInstallationStatus = () => {
            if (window.matchMedia('(display-mode: standalone)').matches) {
                setIsInstalled(true);
            }
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        window.addEventListener('appinstalled', () => setIsInstalled(true));
        checkInstallationStatus();

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            window.removeEventListener('appinstalled', () => setIsInstalled(true));
        };
    }, []);



    const handleAddToHomeScreenClick = () => {
        console.log(deferredPrompt);
        if (isInstalled) return;
        if (deferredPrompt) {

            // Prompt the user for installation
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    setIsInstalled(true);
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };
    const playVideo = (data) => {
        setCurPlayGame(data);
        setFullVideo(true);
    }
    const playVideoTag = (data) => {
        setGame(data.game)
        const selectr = data.tagList[0].start_time ? 'start_time' : 'player_tag_start_time'
        setPlayTags(stableSort(stableSort(addVideoUrl(data.tagList), getComparator('asc', selectr)), getComparator('asc', 'game_id')));
        setOpen(true)
    }

    const addVideoUrl = (tagList) => {
        const tagLisnew = [];
        tagList.map((tag, index) => {
            const game = games.find(game => game.game_id === tag.game_id)
            const url = game?.mobile_video_url ? game?.mobile_video_url : game?.video_url
            var videoUrl = url;
            if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
                GameService.getNewStreamURL(url).then((res) => {
                    videoUrl = res.result.video_url;
                });
            }
            tagLisnew.push({
                ...tag,
                url: videoUrl,
            })
        })
        return tagLisnew
    }
    const onMenuChange = (tabname, data) => {
        var dt = data
        setGameStatsData(dt)
        switch (tabname) {
            case 'details':
                setAdvanceStats(false)
                setSkillsPage(false)
                setDetailsPage(true)
                setGameStats(false)
                setDisableSeason(false)
                break;
            case 'advance_stats':
                setAdvanceStats(true)
                setSkillsPage(false)
                setDetailsPage(false)
                setGameStats(false)
                setDisableSeason(false)
                break;
            case 'skills':
                setAdvanceStats(false)
                setSkillsPage(true)
                setDetailsPage(false)
                setGameStats(false)
                setDisableSeason(false)
                break;
            case 'game_stats':
                setAdvanceStats(false)
                setSkillsPage(false)
                setDetailsPage(false)
                setGameStats(true)
                setDisableSeason(true)
                break;
            default:
        }
    };
    const onSeasonChange = (season) => {
        setSeasonFilter(season);
    }
    if (isActivated) {
        return (
            <ThemeProvider theme={theme}>
                <PlayerContext.Provider value={value}>
                    {loading && (
                        <div style={styles.loader}>
                            <CircularProgress />
                        </div>
                    )}
                    <Box className="profileSection" style={{ backgroundColor: "#2c4b5f"/*"#0e3547"*/ }}>
                        {!isInstalled && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddToHomeScreenClick}
                                // hidden={!deferredPrompt ?? "hidden"}
                                hidden={"hidden"}
                            >
                                Install to device
                            </Button>
                        )}
                        <div className="bodyBackground no-select">
                            <Header player={playerData} onMenuChange={onMenuChange} seasonList={seasonList} onSeasonChange={onSeasonChange} selected={seasonFilter} disableSeason={disableSeason} />
                            {playerData && detailsPage && <PlayerDetailCard player={playerData} gameList={getGameList()} onMenuChange={onMenuChange} playVideo={playVideo} handleAddToHomeScreenClick={handleAddToHomeScreenClick} />}
                            {skillsPage && <div className="player-action-outer"><PlayerSkills player={playerData} gameList={getGameList()} onMenuChange={onMenuChange} seasonFilter={seasonFilter} playVideoTag={playVideoTag} t={t} /></div>}
                            {advanceStatsPage && <div className="player-action-outer"><PlayerAdvanceStatsCard player={playerData} gameList={getGameList()} onMenuChange={onMenuChange} seasonFilter={seasonFilter} t={t} playVideo={playVideo} playVideoTag={playVideoTag} /></div>}
                            {gameStatsPage && <div className="player-action-outer"><PlayerGameStatsCard player={playerData} gameData={gameStatsPageData} onMenuChange={onMenuChange} gameList={getGameList()} t={t} playVideoTag={playVideoTag} seasonFilter={seasonFilter} /></div>}
                        </div>
                        <PaperStyledDialog className="profileSection_tagvideo_mobile" open={open} onClose={(e) => setOpen(false)}>
                            <DialogContent sx={{ p: 0 }}>
                                <TagVideo
                                    tagList={playTags}
                                    url={game?.mobile_video_url ? game?.mobile_video_url : game?.video_url}
                                    muteState={game?.mute_video}
                                    setOpen={setOpen}
                                    gameId={game?.game_id}
                                    changeGame={setGame}
                                    cur_player_id={playerId}
                                />
                            </DialogContent>
                        </PaperStyledDialog>
                        <PaperStyledDialog className="profileSection_tagvideo_mobile" sx={{ width: '100%', height: '100%' }} open={fullVideo} onClose={(e) => setFullVideo(false)}>
                            <DialogContent sx={{ p: 0 }}>
                                <FullVideoPlayer
                                    video_url={curPlayGame ? (curPlayGame?.mobile_video_url ? curPlayGame?.mobile_video_url : curPlayGame?.video_url) : ''}
                                    muteState={curPlayGame?.mute_video ?? false}
                                    setFullVideo={setFullVideo}
                                    IsSkills={false}
                                />
                            </DialogContent>
                        </PaperStyledDialog>
                    </Box>
                </PlayerContext.Provider>
            </ThemeProvider>
        );

    }
    return (
        <ThemeProvider theme={theme}>
            <PlayerContext.Provider value={value}>
                <Box className="profileSection" style={{ backgroundColor: "#2c4b5f"/*"#0e3547"*/, }}>
                    {checkingActive === false && (<div className="bodyBackground no-select">
                        <Box style={styles.pageDescription} sx={{
                            fontSize: {
                                xs: '24px',
                                sm: '32px',
                                md: '48px',
                                lg: '64px',
                            }
                        }}>
                            The player is not active
                        </Box>
                    </div>)}
                </Box>
            </PlayerContext.Provider>
        </ThemeProvider>
    );


}